
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { handleImageSrc } from '~/helpers';
import { useMediaAsset } from '~/composables';

export default defineComponent({
  props: {
    slug: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    image: {
      type: [String, Object],
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { getAssetUrl } = useMediaAsset();
    const imageSrc = computed(() => getAssetUrl(handleImageSrc(props.image)));

    const isExternalLink = (link = '') => /^https?:/.test(link);

    const getNewsItemUrl = (url?: string) => {
      if (url) return isExternalLink(url) ? url : `/${url.replace(/^\/+/, '')}`;
      return false;
    };

    return {
      imageSrc,
      getNewsItemUrl,
    };
  },
});
