
import { defineComponent } from '@nuxtjs/composition-api';
import ProductUserHelpDownloads from '~/components/sections/productSection/ProductUserHelpDownloads.vue';
import ProductUserHelpVideos from '~/components/sections/productSection/ProductUserHelpVideos.vue';

export default defineComponent({
  components: { ProductUserHelpVideos, ProductUserHelpDownloads },
  props: {
    manuals: {
      type: Array,
      default: () => [],
    },
    videos: {
      type: Array,
      default: () => [],
    },
    faqItems: {
      type: Array,
      default: () => [],
    },
  },
});
