
import { defineComponent } from '@nuxtjs/composition-api';
import { useMediaAsset, useBreakpoint } from '~/composables';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
  },
  setup() {
    const { getAssetUrl } = useMediaAsset();
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    return {
      isDesktop,
      getAssetUrl,
    };
  },
});
