import { kebabToPascal } from '~/helpers';
import type {
  Block,
  CatalogBlock,
  PreparedComponent,
  PrepareDynamicBlockOptions,
} from '~/types';

function getItemsData(data?: any[] | Record<string, any> | null) {
  if (data === undefined || data === null) return {};
  return Array.isArray(data) ? { items: data } : data;
}

function getComponentTypeFilteredByAliases(
  type: string,
  componentsAliases: Record<string, string> = {},
): string {
  return componentsAliases[type] ?? type;
}

function isComponentsWithDataProps(
  name: string,
  // eslint-disable-next-line default-param-last
  excludeNamesFromCheck: string[] = [],
  sectionsWithDataProps: string[],
) {
  return name && !excludeNamesFromCheck.includes(name)
    ? sectionsWithDataProps.includes(kebabToPascal(name))
    : false;
}

function getComponentBindingDataByNameAndBlockData(
  isComponentWithDataProps: boolean,
  data: any[] | Record<string, any>,
) {
  return !isComponentWithDataProps
    ? getItemsData(data)
    : { data: getItemsData(data) };
}

function getComponentData(block: Block | CatalogBlock) {
  const isAsbisUiFromCatalog =
    'content_type' in block && block.content_type === 'asbis-ui';

  if ('content' in block) {
    return typeof block.content === 'string'
      ? block.content
      : block.content.data;
  }
  return isAsbisUiFromCatalog ? block.data.data : block.data;
}

function getComponentType(block: Block | CatalogBlock) {
  const dataFromBlock = getComponentData(block);
  const notComponentType = !('content' in block)
    ? block.content_type
    : block.type;
  if (notComponentType === 'textarea' || typeof dataFromBlock === 'string')
    return 'html-content-block';

  if (!('content' in block)) return block.type;

  return typeof block.content !== 'string' ? block.content.type : block.type;
}

function getBlockInfo(block: Block | CatalogBlock) {
  return {
    blockType: getComponentType(block),
    blockData: getComponentData(block),
  };
}

function getComponentByBlock(
  blockInfo: { originalBlock: Block | CatalogBlock } & PreparedComponent,
) {
  const { originalBlock, data, type } = blockInfo;

  const isFromStaticPage = 'content' in originalBlock;

  const component = {
    ...(isFromStaticPage &&
      typeof originalBlock.content !== 'string' &&
      originalBlock.content),
    type,
    data,
  };

  return component;
}

function cmsBlockToComponentSerializer(
  block: CatalogBlock | Block,
  options?: PrepareDynamicBlockOptions,
): PreparedComponent | object {
  const excludedBlocks = options?.excludedBlocks ?? [];
  const aliases = options?.aliases ?? {};
  const noDataPropsSections = options?.noDataPropsSections ?? [];
  const sectionsWithDataProps = options?.sectionsWithDataProps ?? [];

  const { blockType, blockData } = getBlockInfo(block);
  const componentType = getComponentTypeFilteredByAliases(blockType, aliases);
  const isComponentWithDataProps = isComponentsWithDataProps(
    blockType,
    noDataPropsSections,
    sectionsWithDataProps,
  );
  const componentData = getComponentBindingDataByNameAndBlockData(
    isComponentWithDataProps,
    blockData,
  );

  const component = getComponentByBlock({
    originalBlock: block,
    type: componentType,
    data: componentData,
  });

  if (excludedBlocks?.includes(component?.type)) return {};

  return component;
}

export default cmsBlockToComponentSerializer;
