
import { defineComponent } from '@nuxtjs/composition-api';
import { useMediaAsset, useBreakpoint } from '~/composables';

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { getAssetUrl } = useMediaAsset();
    const { isBiggerThanBreakpoint: isBiggerSm } = useBreakpoint('sm');

    const getIcon = (item) =>
      getAssetUrl(isBiggerSm.value ? item.icon?.desktop : item.icon?.mobile);

    return {
      getIcon,
    };
  },
});
