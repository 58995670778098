
import { defineComponent, useFetch, computed } from '@nuxtjs/composition-api';
import { usePage, useBreadcrumbs } from '~/composables';

export default defineComponent({
  setup() {
    const { breadcrumbs, addCrumbs } = useBreadcrumbs();
    const { page, fetchPage } = usePage();

    useFetch(async () => {
      await fetchPage('404');
      addCrumbs('404');
    });

    const isCategoriesSection = computed({
      get() {
        return !!page?.value?.blocks?.find(
          (block) => block?.content?.type === 'categories-section',
        );
      },
      set() {},
    });

    return {
      isCategoriesSection,
      page,
      breadcrumbs,
      addCrumbs,
    };
  },
  head: {},
});
