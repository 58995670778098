
import { defineComponent } from '@nuxtjs/composition-api';
import SoftwareCategoryTryDemoForm from '~/components/sections/categorySection/SoftwareCategoryTryDemoForm.vue';
import { useModalDialog } from '~/composables';

export default defineComponent({
  components: {
    SoftwareCategoryTryDemoForm,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isOpen: isFormModalOpen } = useModalDialog(props.data.id);
    const { isOpen: isInfoModalOpen, close: closeInfoModal } = useModalDialog(
      `${props.data.id}-info`,
    );

    return {
      isFormModalOpen,
      isInfoModalOpen,
      closeInfoModal,
    };
  },
});
