import {
  useBreadcrumbs,
  useBreakpoint,
  useCountries,
  useCountryByLang,
  useFetchCategory,
  useFetchDocuments,
  useFetchFaqs,
  useFetchProducts,
  useForm,
  useMediaAsset,
  useModalDialog,
  useNews,
  useOpenClose,
  usePage,
  usePagePreloader,
  usePagination,
  useProductPage,
  useRecaptcha,
  useScrollTrigger,
  useSwiperAutoplayProgress,
  useTabs,
  useTab,
} from '@webplatform/frontkit/dist/composables';

export {
  useBreadcrumbs,
  useBreakpoint,
  useCountries,
  useCountryByLang,
  useFetchCategory,
  useFetchDocuments,
  useFetchFaqs,
  useFetchProducts,
  useForm,
  useMediaAsset,
  useModalDialog,
  useNews,
  useOpenClose,
  usePage,
  usePagePreloader,
  usePagination,
  useProductPage,
  useRecaptcha,
  useScrollTrigger,
  useSwiperAutoplayProgress,
  useTabs,
  useTab,
};
