import { render, staticRenderFns } from "./SoftwareCategoryTryDemoForm.vue?vue&type=template&id=86744ca2"
import script from "./SoftwareCategoryTryDemoForm.vue?vue&type=script&lang=ts"
export * from "./SoftwareCategoryTryDemoForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInput: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiInput.vue').default,UiSelect: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiSelect.vue').default,UiCheckbox: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiCheckbox.vue').default,UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiButton.vue').default})
