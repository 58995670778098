import { computed, onMounted, ref, watch } from 'vue';
import { useMediaAsset } from '~/composables';


export default {
  __name: 'UiVideo',
  props: {
  src: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  play: {
    type: Boolean,
    default: false,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  controls: {
    type: Boolean,
    default: false,
  },
  muted: {
    type: Boolean,
    default: true,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  containerSize: {
    type: Boolean,
    default: false,
  },
  videoClass: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const props = __props;



const { getAssetUrl } = useMediaAsset();
const video = ref();

function getYoutubeId(url = '') {
  if (url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;

    const match = url.match(regExp);
    if (match && match[2]) return match[2];
  }
  return false;
}

const youtubeVideoId = computed(() => props.id || getYoutubeId(props.src));

const videoMuted = computed({
  get() {
    return props.muted ? 'muted' : null;
  },
  set(value) {
    if (video.value) video.value.setAttribute('muted', value ? 'muted' : null);
  },
});

const start = () => {
  videoMuted.value = props.muted;
  video.value?.play();
};
const stop = () => {
  video.value?.pause();
};

watch(
  () => props.play,
  (doPLay) => {
    doPLay ? start() : stop();
  },
);

onMounted(() => {
  videoMuted.value = props.muted;
});

return { __sfc: true,props, getAssetUrl, video, getYoutubeId, youtubeVideoId, videoMuted, start, stop }
}

}