
import {
  computed,
  defineComponent,
  nextTick,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import NewsItem from '~/components/sections/newsSection/NewsItem.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

const ALL_TAGS = 'All tags';

export default defineComponent({
  components: { NewsItem, Swiper, SwiperSlide },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const swiper = ref(null);

    const swiperOptions = {
      observer: true,
      slidesPerView: 'auto',
      spaceBetween: 16,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        dragSize: 180,
        snapOnRelease: true,
      },
      lazy: {
        enabled: true,
        checkInView: true,
        loadPrevNext: true,
        loadPrevNextAmount: 5,
      },
    };

    const activeTag = ref(ALL_TAGS);

    const visibleNews = computed(() =>
      activeTag.value === ALL_TAGS
        ? props.data.items
        : props.data?.items?.filter((item: any) =>
            item.tags?.includes(activeTag.value),
          ),
    );

    const allTags = computed(() => {
      const tags = [
        ...new Set(
          (props.data as any)?.items
            ?.map((item: any) => item.tags ?? [])
            .flat()
            .filter(Boolean),
        ),
      ];

      if (tags.length) tags.unshift(ALL_TAGS);
      return tags;
    });

    watch(visibleNews, () => {
      nextTick(() => {
        (swiper.value as any)?.$swiper.lazy.load();
      });
    });

    const selectTag = (tag: string) => {
      activeTag.value = activeTag.value === tag ? ALL_TAGS : tag;
    };

    return {
      swiperOptions,
      swiper,
      allTags,
      activeTag,
      visibleNews,
      selectTag,
    };
  },
});
