
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useMediaAsset, useBreakpoint } from '~/composables';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { getAssetUrl } = useMediaAsset();
    const { isBiggerThanBreakpoint: isBiggerSm } = useBreakpoint('sm');
    const { isBiggerThanBreakpoint: isBiggerMd } = useBreakpoint('md');

    const backgroundImage = computed(() =>
      getAssetUrl(
        isBiggerMd.value
          ? props.data.backgroundImageDesktop
          : props.data.backgroundImageMobile,
      ),
    );

    return {
      isBiggerSm,
      isBiggerMd,
      backgroundImage,
    };
  },
});
