import { render, staticRenderFns } from "./error.vue?vue&type=template&id=6b90f4ee"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBreadcrumbs: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/AppBreadcrumbs.vue').default,UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiIcon.vue').default,UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/ui/UiButton.vue').default,PageBlocks: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/MODODOMUS/MODODOMUS_Deploy_Prod/components/PageBlocks.vue').default})
