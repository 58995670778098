
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useMediaAsset, useBreakpoint } from '~/composables';
import CategoryFeaturesBlocks from '~/components/sections/categorySection/CategoryFeaturesBlocks.vue';
import CategoryFeaturesBlocksMobile from '~/components/sections/categorySection/CategoryFeaturesBlocksMobile.vue';

export default defineComponent({
  components: {
    CategoryFeaturesBlocks,
    CategoryFeaturesBlocksMobile,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
  },
  setup(props) {
    const { getAssetUrl } = useMediaAsset();
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    const items = computed(() =>
      props.data.items.map((item: any) => ({
        ...item,
        image: getAssetUrl(item.image),
      })),
    );

    return {
      isDesktop,
      items,
    };
  },
});
