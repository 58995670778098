
import { defineComponent, useStore, computed } from '@nuxtjs/composition-api';
import { useCountryByLang, useMediaAsset } from '@/composables';

export default defineComponent({
  setup() {
    const { getAssetUrl } = useMediaAsset();
    const store = useStore();
    const config = store.getters['config/getConfig'];

    const currentLocale = computed(() =>
      config.language.find((l: any) => l.default),
    );
    const currentLocaleCode = computed(() => currentLocale.value?.code ?? 'en');

    const options = computed(() => config.languages ?? []);

    const isShowed = computed(() => options.value.length > 1);

    const getFlagIcon = (code: string) => {
      return `/svg/flags/${useCountryByLang(code)?.toUpperCase()}.svg`;
    };

    const flagBgStyle = (code: string) => {
      const img = getAssetUrl(getFlagIcon(code), {
        width: 24,
        height: 16,
      });

      return { 'background-image': `url(${img})` };
    };

    return {
      options,
      config,
      flagBgStyle,
      currentLocale,
      currentLocaleCode,
      isShowed,
    };
  },
});
