
import { defineComponent } from '@nuxtjs/composition-api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { useBreakpoint } from '~/composables';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    const swiperOptions = {
      observer: true,
      slidesPerView: 'auto',
      spaceBetween: 16,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        dragSize: 180,
        snapOnRelease: true,
      },
    };

    return {
      isDesktop,
      swiperOptions,
    };
  },
});
