
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useMediaAsset } from '~/composables';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

interface CategoryApp {
  id: string;
  name: string;
  text: string;
}

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const swiperOptions = {
      observer: true,
      slidesPerView: 'auto',
      spaceBetween: 16,
      breakpoints: {
        1024: {
          slidesPerView: 4,
        },
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        dragSize: 180,
        snapOnRelease: true,
      },
    };

    const { getAssetUrl } = useMediaAsset();

    const apps = computed(() => {
      return !props.data?.items
        ? []
        : (props.data.items as CategoryApp[]).reduce((items, item, i) => {
            const chunkIndex = Math.floor(i / 2);

            // eslint-disable-next-line no-param-reassign
            items[chunkIndex] = items[chunkIndex] || [];
            // @ts-ignore
            items[chunkIndex].push(item);

            return items;
          }, []);
    });

    return {
      swiperOptions,
      apps,
      getAssetUrl,
    };
  },
});
