
import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { useMediaAsset } from '~/composables';
import { useElementHover } from '@vueuse/core';

export default defineComponent({
  components: {},
  props: {
    menuItem: {
      type: Object,
      default() {
        return {};
      },
    },
    isActive: {
      type: Boolean,
    },
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const menuLink = ref();
    const isHoveredLink = useElementHover(menuLink);
    const { getAssetUrl } = useMediaAsset();
    const activeCategoryId = ref(null);
    const activeCategoryName = ref(null);
    const activeCategoryImage = ref(null);
    const activeCategoryChildren = ref(null);

    const hasChildren = computed(() =>
      Boolean(props.menuItem.children?.length),
    );

    const hasImages = computed(
      () =>
        hasChildren.value && props.menuItem.children.some((item) => item.image),
    );

    const setActiveCategory = (category) => {
      activeCategoryId.value = category?.id ?? null;

      activeCategoryName.value = category?.name ?? null;

      activeCategoryChildren.value =
        category?.children && category?.children.length
          ? category?.children
          : null;

      activeCategoryImage.value = category?.image?.path
        ? `url(${getAssetUrl(category.image.path)})`
        : '';
    };

    watch(isHoveredLink, (value) => {
      emit('set-active-item');
      if (!value) setActiveCategory();
    });

    return {
      menuLink,
      hasChildren,
      hasImages,
      activeCategoryId,
      activeCategoryImage,
      setActiveCategory,
      activeCategoryChildren,
      activeCategoryName,
    };
  },
});
