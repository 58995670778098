
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useMediaAsset, useBreakpoint } from '~/composables';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { getAssetUrl } = useMediaAsset();
    const { isBiggerThanBreakpoint: isMobile } = useBreakpoint('md');

    const backgroundImage = computed(() =>
      getAssetUrl(isMobile.value ? props.data.image : props.data.imageMobile),
    );

    const markersBoardImageStyles = computed(() => {
      const styles = props.data.markersBoard?.styles;
      return isMobile.value ? styles.desktop : styles.mobile;
    });

    return {
      isMobile,
      backgroundImage,
      markersBoardImageStyles,
    };
  },
});
