
import { defineComponent, ref } from '@nuxtjs/composition-api';
import SolutionsCarouselSlider from '~/components/sections/sliderSection/SolutionsCarouselSlider.vue';
import SolutionsCarouselSliderMobile from '~/components/sections/sliderSection/SolutionsCarouselSliderMobile.vue';
import VideoPlayerModal from '~/components/VideoPlayerModal.vue';
import { useModalDialog, useBreakpoint } from '~/composables';

export default defineComponent({
  components: {
    SolutionsCarouselSliderMobile,
    SolutionsCarouselSlider,
    VideoPlayerModal,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    const sliderNav = ref();
    const activeSlide = ref(0);
    const videoPlayer = ref();
    const videoInModal = ref<null | string>(null);
    const swiper = ref<any>();
    const swiperMobile = ref<any>();
    const { isOpen: isModalOpen, open, close } = useModalDialog('video-player');

    const playVideo = (videoId: string) => {
      videoInModal.value = videoId;
      open();
    };

    const closeVideo = () => {
      videoInModal.value = null;
      close();
    };

    const onSlideChange = (i: number) => {
      activeSlide.value = i;

      const nav = sliderNav.value as HTMLElement;
      const navButton = nav.children[i] as HTMLElement;

      nav.parentElement!.scroll({
        left: navButton.offsetLeft - navButton.offsetWidth,
      });
    };

    const changeSlide = (i: number) => {
      if (swiper.value && !swiper.value.destroyed) swiper.value.slideTo(i);

      if (swiperMobile.value && !swiperMobile.value.destroyed)
        swiperMobile.value.slideTo(i);
    };

    const onSwiperInit = (slider: any) => {
      swiper.value = slider;
    };

    const onSwiperMobileInit = (slider: any) => {
      swiperMobile.value = slider;
    };

    return {
      swiper,
      swiperMobile,
      sliderNav,
      isDesktop,
      activeSlide,
      videoPlayer,
      videoInModal,
      isModalOpen,
      onSwiperInit,
      onSwiperMobileInit,
      onSlideChange,
      changeSlide,
      playVideo,
      closeVideo,
    };
  },
});
