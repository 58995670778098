import {
  checkPublicEmailDomains,
  handleImageAlt,
  handleImageSrc,
  isTouchDevice,
  kebabToPascal,
  pascalToKebab,
  setProtocol,
  getProductCustomLink,
  getProductDescription,
  getProductImage,
  getProductLink,
  getProductTags,
} from '@webplatform/frontkit/dist/helpers';

export {
  checkPublicEmailDomains,
  handleImageAlt,
  handleImageSrc,
  isTouchDevice,
  kebabToPascal,
  pascalToKebab,
  setProtocol,
  getProductCustomLink,
  getProductDescription,
  getProductImage,
  getProductLink,
  getProductTags,
};
