
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  useStore,
  watch,
  useContext,
} from '@nuxtjs/composition-api';

import { useModalDialog, useForm, useCountries } from '~/composables';

interface FormData {
  type: string;
  site: string;
  lang: string;
  name: string;
  phone: string;
  email: string;
  country: string;
  company: string;
  personalData: boolean;
}

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const config = store.getters['config/getConfig'];
    const lang: string = store.getters['config/getActiveLanguage'];
    const form = ref();
    const { formSuccess, formPending, submit, recaptcha } = useForm();
    const recaptchaClicked = ref(false);
    const tryDemoModal = useModalDialog('try-demo-form');
    const infoModal = useModalDialog('try-demo-form-info');
    const { $gtm } = useContext();

    onMounted(async () => {
      await recaptcha.init();
    });

    const formData = reactive<FormData>({
      type: 'modo_joinu_try_free',
      site: config.domain,
      lang,
      name: '',
      phone: '',
      email: '',
      country: '',
      company: '',
      personalData: false,
    });
    const isFormStartEventSended = ref(false);
    const confirm = ref(false);
    const { getCountriesList } = useCountries();
    const countries = getCountriesList();

    const openFile = () => {
      if (!props.data.file?.link) return;
      window.open(props.data.file.link, '_self');
    };

    const clearForm = () => {
      confirm.value = false;

      const formDataKeys: Array<
        keyof Omit<FormData, 'type' | 'site' | 'lang'>
      > = ['name', 'email', 'phone', 'country', 'company', 'personalData'];
      formDataKeys.forEach((key) => {
        if (typeof formData[key] === 'string') (formData[key] as string) = '';
        if (typeof formData[key] === 'boolean')
          (formData[key] as boolean) = false;
      });

      nextTick(() => form.value.reset());
    };

    const submitForm = async () => {
      try {
        await submit({ ...formData });
        openFile();
        clearForm();
        tryDemoModal.close();
        infoModal.open();
      } catch (error) {
        console.error('Error submitting form', error);
      }
    };

    const pushFormStartEvent = () => {
      if (!isFormStartEventSended.value) {
        $gtm.push({
          event: 'form_start',
        });
      }
      isFormStartEventSended.value = true;
    };

    watch(formData, () => {
      pushFormStartEvent();
    });
    watch(confirm, () => {
      pushFormStartEvent();
    });

    return {
      infoModal,
      form,
      formData,
      confirm,
      countries,
      formSuccess,
      formPending,
      recaptcha,
      recaptchaClicked,
      submitForm,
    };
  },
});
